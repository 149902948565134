<template>
  <nav class="nav-user">
    <div class="nickname">
      {{ userNickname }}
    </div>
    <nuxt-link
      prefetch
      :to="localizePath(profileUrl)"
      class="item"
      :class="{ 'is-active': route.path === localizePath(profileUrl) }"
      @click.prevent="clickItem(profileUrl)"
    >
      <atomic-icon :id="getContent(layoutData, defaultLocaleLayoutData, 'profileSidebar.profileInfo.icon')" />
      {{ getContent(layoutData, defaultLocaleLayoutData, 'profileSidebar.profileInfo.label') }}
    </nuxt-link>
    <atomic-divider/>

    <div class="items">
      <nuxt-link
        v-for="(item, index) in profileLinks"
        :key="index"
        prefetch
        :to="item.url"
        class="item"
        :class="{ 'is-active': route.path === localizePath(item.url) }"
        @click.prevent="clickItem(item.url)"
      >
        <atomic-icon :id="item.icon" />
        {{ item.label }}
      </nuxt-link>
    </div>

    <atomic-divider/>
    <button-logout @logout="emit('logout')"/>
  </nav>
</template>

<script setup lang="ts">
  import {storeToRefs} from 'pinia';
  import { USER_MENU } from '@skeleton/consts/menus/user';
  import { buildMenu } from '@skeleton/utils/menuBuilder';

  const emit = defineEmits(['logout', 'close-user-nav']);
  const { localizePath, handleExternalLink } = useProjectMethods();
  const {
    layoutData,
    defaultLocaleLayoutData
  } = storeToRefs(useGlobalStore());
  const route = useRoute();
  const profileStore = useProfileStore();
  const { userNickname } = storeToRefs(profileStore);

  const { getContent } = useProjectMethods();

  const profileUrl = computed(() => getContent(layoutData.value, defaultLocaleLayoutData.value, 'profileSidebar.profileInfo.url'));

  const profileLinks = computed((): IMenuLink[] => {
    return buildMenu(USER_MENU, layoutData.value, defaultLocaleLayoutData.value);
  });

  const clickItem = (url: string):void => {
    emit('close-user-nav');
    handleExternalLink(url);
  };
</script>

<style src="~/assets/styles/components/nav/user.scss" lang="scss" />

