<template>
  <header class="app-header-root">
    <client-only>
      <pwa v-if="isLoggedIn" display="mobile" />
    </client-only>

    <div ref="appHeader" class="app-header" :class="headerClassValue">
      <div class="app-header-root__left">
        <button-toggle-drawer @toggle-minimize="compactDrawer(!isDrawerCompact)" @toggle-open="emit('toggle-open')" />

        <atomic-logo />

        <layout-header-base-menu class="app-header__base-menu" />
      </div>

      <div class="items">
        <button-search @show-search="openModal(ModalName.GLOBAL_SEARCH)" />

        <client-only>
          <template v-if="isLoggedIn">
            <pwa display="desktop" />

            <form-input-deposit />

            <div v-click-outside="closeUserNav" class="nav-user__wrap">
              <div class="nav-user__header-avatar">
                <atomic-avatar @click="toggleProfileNav" />
              </div>

              <nav-user @close-user-nav="closeUserNav" @logout="logout" />
            </div>

            <notification-activator />
          </template>

          <div v-if="!isLoggedIn && !isTelegramUser" class="app-header__auth-buttons">
            <button-base type="gray" size="md" @click="openModalSync(ModalName.SIGN_IN)">
              <span class="btn-secondary__text">
                {{ getContent(layoutData, defaultLocaleLayoutData, 'header.loginButton') }}
              </span>
            </button-base>

            <button-base type="green" size="md" @click="openModalSync(ModalName.SIGN_UP)">
              <span class="btn-secondary__text">
                {{ getContent(layoutData, defaultLocaleLayoutData, 'header.registerButton') }}
              </span>
            </button-base>
          </div>

          <nav-support />

          <atomic-select-lang isHeader />
        </client-only>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
  import { ModalName } from '@skeleton/consts/modals';

  const emit = defineEmits(['login', 'register', 'logout', 'toggle-open']);

  const layoutStore = useLayoutStore();
  const profileStore = useProfileStore();
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();
  const { openModal, openModalSync } = useModalStore();

  const { compactDrawer } = layoutStore;
  const { isLoggedIn, isTelegramUser } = storeToRefs(profileStore);
  const { isGamePage, isDrawerCompact } = storeToRefs(layoutStore);

  const isUserNavOpen = ref(false);

  function openUserNav(): void {
    isUserNavOpen.value = true;
    document.body.classList.add('nav-user-open');
  }

  function closeUserNav(): void {
    isUserNavOpen.value = false;
    document.body.classList.remove('nav-user-open');
  }

  const appHeader = ref<HTMLElement>();

  const headerClassModifiers = computed(() => {
    if (isGamePage.value && isLoggedIn.value) {
      return 'app-header--is-game-page-login';
    } else if (isGamePage.value && !isLoggedIn.value) {
      return 'app-header--is-game-page-logout';
    } else return '';
  });

  const headerClassValue = ref<string>('');

  watch(
    () => headerClassModifiers.value,
    newValue => {
      headerClassValue.value = newValue;
    }
  );

  function toggleProfileNav(): void {
    if (isUserNavOpen.value) {
      closeUserNav();
    } else {
      openUserNav();
    }
  }

  function logout(): void {
    closeUserNav();
    emit('logout');
  }

  onMounted(() => {
    headerClassValue.value = headerClassModifiers.value;
  });
</script>

<style src="~/assets/styles/components/layout/header.scss" lang="scss" />
