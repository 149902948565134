<template>
  <footer class="app-footer">
    <nav class="app-footer__nav">
      <nav-group
        v-if="platformMenu?.length"
        :title="getContent(layoutData, defaultLocaleLayoutData, 'footer.platformMenu.title')"
        :items="platformMenu"
      />

      <nav-group
        v-if="platformMenu?.length"
        :title="getContent(layoutData, defaultLocaleLayoutData, 'footer.promoMenu.title')"
        :items="promoMenu"
      />

      <nav-group
        v-if="infoMenu?.length"
        :title="getContent(layoutData, defaultLocaleLayoutData, 'footer.infoMenu.title')"
        :items="infoMenu"
      />
    </nav>

    <div class="app-footer__controls">
      <div class="app-footer__controls-top">
        <list-socials class="app-footer__socials" :items="socials" />
        <atomic-select-lang class="app-footer__component-desktop" />
        <bottom-sheet-select-lang class="app-footer__component-mobile" />
      </div>
      <div class="app-footer__controls-bottom">
        <atomic-link :to="localizePath('/static/vip_club')">
          <div class="app-footer__color-link app-footer__color-link_vip">
            <atomic-icon id="vip" />
            <span>{{
              getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.topMenu.integratedElementVip.label')
            }}</span>
          </div>
        </atomic-link>
        <atomic-link v-if="!isTelegramUser" :to="botURL">
          <div class="app-footer__color-link app-footer__color-link_tg">
            <atomic-icon id="telegram" />
            <span>Telegram Bot</span>
          </div>
        </atomic-link>
      </div>
    </div>

    <div class="app-footer__licence">
      <licence class="app-footer__licence-items" />
    </div>

    <div class="app-footer__partners">
      <list-partners />
    </div>

    <client-only>
      <list-paysis v-if="!isTelegramUser" class="app-footer__paysys" />
    </client-only>

    <div
      class="app-footer__info"
      v-if="getContent(layoutData, defaultLocaleLayoutData, 'footer.curacaoLicence.description')"
      v-html="marked.parse(getContent(layoutData, defaultLocaleLayoutData, 'footer.curacaoLicence.description'))"
    />

    <div class="app-footer__copyright">
      {{ getContent(layoutData, defaultLocaleLayoutData, 'footer.copyright') }}
    </div>
  </footer>
</template>

<script setup lang="ts">
  import { marked } from 'marked';

  import { FOOTER_INFO_MENU, FOOTER_PLATFORM_MENU, FOOTER_PROMO_MENU } from '@skeleton/consts/menus/footer';
  import { buildMenu } from '@skeleton/utils/menuBuilder';

  import type { ISocialLinkItem } from '~/types';

  const {
    public: { botURL },
  } = useRuntimeConfig();
  const { getContent, localizePath } = useProjectMethods();
  const { isTelegramUser } = storeToRefs(useProfileStore());
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());

  const platformMenu = computed((): IMenuLink[] => {
    return buildMenu(FOOTER_PLATFORM_MENU, layoutData.value, defaultLocaleLayoutData.value);
  });

  const promoMenu = computed((): IMenuLink[] => {
    const menu = buildMenu(FOOTER_PROMO_MENU, layoutData.value, defaultLocaleLayoutData.value);
    return [...menu, ...getContent(layoutData.value, defaultLocaleLayoutData.value, 'siteSidebar.bonusesMenu.items', [])];
  });

  const infoMenu = computed((): IMenuLink[] => {
    return buildMenu(FOOTER_INFO_MENU, layoutData.value, defaultLocaleLayoutData.value);
  });

  const socials = computed<ISocialLinkItem[]>(() => {
    return getContent(layoutData.value, defaultLocaleLayoutData.value, 'footer.socialIcons', []);
  });
</script>

<style src="~/assets/styles/components/layout/footer.scss" lang="scss" />
