<template>
  <div class="header-base-menu">
    <nuxt-link
      v-for="(button, idx) in buttons"
      :key="button.label"
      class="header-base-menu__item"
      :to="localizePath(button.pageUrl)"
      @mouseenter="playAnimation(button.label, 'On')"
      @mouseleave="playAnimation(button.label, 'Off')"
      :class="[
        'header-base-menu__link',
        {
          'header-base-menu__link_active':
            route.path === localizePath(button.pageUrl) || (!idx && additionalCasinoActive),
        },
      ]"
    >
      <video v-lazy-load 
        class="header-base-menu__video header-base-menu__video_active"
        :ref="el => setVideoRef(el, button.label, 'On')"
        :src="button.videoOn"
        muted
        preload="auto"
      />
      <video v-lazy-load 
        class="header-base-menu__video"
        :ref="el => setVideoRef(el, button.label, 'Off')"
        :src="button.videoOff"
        muted
        preload="auto"
      />
      <div class="header-base-menu__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, `header.headerButtons.${button.label}`) }}
      </div>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
  import { UAParser } from 'ua-parser-js';

  type Video = 'On' | 'Off';

  const route = useRoute();
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const sourcePath = `${gamehubCdn}/video/animations/header/`;
  const { localizePath, getContent } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();

  const browserName = UAParser(window?.navigator?.userAgent)?.browser?.name;
  const movOnly = browserName === 'Safari' || browserName === 'WebKit';
  const extension = movOnly ? '.mov' : '.webm';

  const buttons = [
    {
      label: 'games',
      videoOn: `${sourcePath}dice-on${extension}`,
      videoOff: `${sourcePath}dice-off${extension}`,
      pageUrl: '/games',
    },
    {
      label: 'sports',
      videoOn: `${sourcePath}ball-on${extension}`,
      videoOff: `${sourcePath}ball-off${extension}`,
      pageUrl: '/sport',
    },
  ];

  const videoRefs = ref<Record<string, Element | ComponentPublicInstance | null>>({});

  const setVideoRef = (el: Element | ComponentPublicInstance | null, label: string, type: Video) => {
    if (el) videoRefs.value[`${label}-${type}`] = el;
  };

  const playAnimation = (label: string, evt: Video) => {
    const activeClass = 'header-base-menu__video_active';
    const videoOn = videoRefs.value[`${label}-On`];
    const videoOff = videoRefs.value[`${label}-Off`];

    if (!(videoOn instanceof HTMLVideoElement) || !(videoOff instanceof HTMLVideoElement)) return;

    if (evt === 'On') {
      videoOff.classList.remove(activeClass);
      videoOn.classList.add(activeClass);
      videoOn.currentTime = 0;
      videoOn.play();
    }

    if (evt === 'Off') {
      videoOn.classList.remove(activeClass);
      videoOff.classList.add(activeClass);
      videoOff.currentTime = 0;
      videoOff.play();
    }
  };

  const additionalCasinoActive = computed(() => {
    const routeName = route.name as string;
    return !!route.query.category || routeName?.includes('games-id');
  });
</script>

<style src="~/assets/styles/components/layout/header-base-menu.scss" lang="scss" />
