<template>
  <div class="select-lang">
    <client-only>
      <div class="select-lang__wrap">
        <div class="select-lang__selected" @click="toggleCountrySelector('open')">
          <atomic-image class="img" :src="`${gamehubCdn}/locales/${currentLocale?.code.toLowerCase()}.svg`" />
          <span class="title">{{ currentLocale?.code || currentLocale?.nativeName || currentLocale?.name }}</span>
          <atomic-icon id="arrow_expand-close" />
        </div>
      </div>

      <layout-bottom-sheet ref="languageSelector">
        <template #header>
          <span class="label">{{
            getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.language.label')
          }}</span>
          <form-input-search
            v-model:value="searchValue"
            :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.search.placeholder')"
            @changed="toggleCountrySelector('close')"
          />
        </template>
        <list-languages :searchValue="searchValue" />
        <template #footer>
          <button-base type="gray" size="sm" @click="toggleCountrySelector('close')">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
          </button-base>
        </template>
      </layout-bottom-sheet>
    </client-only>
  </div>
</template>

<script setup lang="ts">
  const globalStore = useGlobalStore();
  const { currentLocale } = storeToRefs(globalStore);
  const { fieldsSettings, defaultLocaleFieldsSettings, popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const isProcess = ref(false);
  const languageSelector = ref();
  const searchValue = ref('');

  const { getContent } = useProjectMethods();

  const toggleCountrySelector = (methodName: 'open' | 'close'): void => {
    if (isProcess.value) return;
    languageSelector.value[methodName]();
  };
</script>

<style src="~/assets/styles/components/bottom-sheet/select-lang.scss" lang="scss" />
