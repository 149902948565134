<template>
  <div class="game-return">
    <div class="game-return__container">
      <div class="game-return__container-info">
        <nuxt-link :to="localizePath(`/game/${game?.identity}?real=true`)">
          <atomic-image class="game-return__game-image" :src="gameImage" />
        </nuxt-link>

        <div class="game-return__content">
          <div>{{ game?.name }}</div>
          <div>
            {{
              getContent(
                layoutData,
                defaultLocaleLayoutData,
                'returnGame.playLabel'
              )
            }}
          </div>
        </div>
      </div>

      <div class="game-return__controls">
        <nuxt-link :to="localizePath(`/game/${game.identity}?real=true`)">
          <atomic-icon id="play" />
        </nuxt-link>
        <span class="close">
          <atomic-icon id="close" @click="setReturnGame('disabled')" />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IGame } from '@skeleton/core/types';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    game: IGame;
  }>();

  const globalStore = useGlobalStore();
  const { setReturnGame } = useLayoutStore();
  const { getImageUrl, getContent, localizePath } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);

  const gameImage = computed(() => {
    return props.game.images['1x1']
      ? getImageUrl(props.game.images, 'square')
      : '/img/default-game-tumb.png';
  });
</script>

<style src="~/assets/styles/components/layout/game-return.scss" lang="scss" />
