<template>
  <div class="notification-activator" @click="openPanel" v-click-outside="closePanel">
    <icon-placeholder>
      <atomic-icon id="notification" />
    </icon-placeholder>

    <div v-if="hasNotifications" class="notification-activator__active"></div>
  </div>

  <transition name="slide-right">
    <notification-panel
      v-if="showPanel"
      :currentLocaleData="content?.currentLocaleData"
      :defaultLocaleData="content?.defaultLocaleData"
      @closePanel="closePanel"
      @click.stop
    />
  </transition>
</template>

<script setup lang="ts">
  import type { INotification } from '~/types';

  const userNotificationsStore = useUserNotificationsStore();
  const { totalUnread } = storeToRefs(userNotificationsStore);

  const contentParams = {
    contentKey: 'profileNotificationContent',
    contentRoute: ['profile', 'notification'],
  };

  const { getContentData } = useContentLogic<INotification>(contentParams);
  const { data: content } = await useLazyAsyncData(getContentData);

  const showPanel = ref<boolean>(false);

  const hasNotifications = computed(() => !!totalUnread.value);

  const openPanel = (): void => {
    showPanel.value = true;
  };

  const closePanel = (): void => {
    if (showPanel.value) {
      showPanel.value = false;
      userNotificationsStore.goToReadByIds();
    }
  };
</script>

<style src="~/assets/styles/components/notification/notification-activator.scss" lang="scss" />
