<template>
  <div class="licence">
    <div class="licence__info">
      <div class="licence__trust">
        <div v-if="gambleTxt?.length" class="licence__txt">
          <span v-for="item in gambleTxt" :key="item">{{ item }}</span>
        </div>
        <div class="licence__icon">18+</div>
      </div>
      <div
        v-show="showLicenceLink"
        id="anj-1a9bab53-765a-41a6-94ea-cebbb375fa5c"
        class="info__image-link"
        style="flex-shrink: 0"
        data-anj-seal-id="1a9bab53-765a-41a6-94ea-cebbb375fa5c"
        data-anj-image-size="40"
        data-anj-image-type="basic-small"
      />
      <atomic-image v-show="!showLicenceLink" class="licence__img" src="/img/license.svg" :width="40" :height="40" />
    </div>
    <atomic-picture src="/img/the-best-crypto.png" :width="96" :height="106" />
  </div>
</template>

<script lang="ts" setup>
  const { getContent } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());

  const {
    public: { apiBaseUrl },
  } = useRuntimeConfig();

  const showLicenceLink = computed(() => {
    return window?.location?.origin === apiBaseUrl;
  });

  const gambleTxt = computed(() => {
    const content = getContent(layoutData.value, defaultLocaleLayoutData.value, 'footer.responsibilityLabel') || '';
    return content.split(' ');
  });

  onMounted(() => {
    try {
      (window as any)['anj_1a9bab53_765a_41a6_94ea_cebbb375fa5c']?.init();
    } catch {
      console.log('licence error');
    }
  });
</script>

<style src="@skeleton/assets/styles/components/licence.scss" lang="scss" />
