export function useCssVars() {
  const updateCssVars = () => {
    nextTick(() => {
      const header = document.querySelector('.app-header') as HTMLElement;
      const headerRoot = document.querySelector('.app-header-root') as HTMLElement;

      if (!header || !headerRoot) return;

      document.body.style.cssText += `
        --header-height: ${header.offsetHeight}px;
        --header-total-height: ${headerRoot.offsetHeight}px;
        --bar-height: ${headerRoot.offsetHeight - header.offsetHeight}px;
      `;
    });
  };

  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  watch(isMobile, () => {
    updateCssVars();
  });

  onBeforeMount(() => {
    updateCssVars();
  });

  return { updateCssVars };
}
