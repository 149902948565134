<template>
  <div class="modal-mobile-game">
    <client-only>
      <layout-bottom-sheet ref="bottomSheet" height="42" spaceBetween @closed="handleSheetClose">
        <div class="modal-mobile-game__container">
          <div class="modal-mobile-game__content">
            <div class="modal-mobile-game__thumb">
              <atomic-image :src="gameImage" />
            </div>

            <div class="modal-mobile-game__info">
              <div class="modal-mobile-game__text">
                <div class="modal-mobile-game__title">
                  {{ mobileGameModalInfo?.name }}
                </div>
                <div class="modal-mobile-game__subtitle">
                  {{ mobileGameModalInfo?.provider?.name }}
                </div>

                <div v-if="mobileGameModalType === 'registerOrLogin'" class="modal-mobile-game__description">
                  {{ modalContent[mobileGameModalType].description }}
                </div>
              </div>

              <div v-if="isLoggedIn" class="modal-mobile-game__icons">
                <button-favorite :gameId="mobileGameModalInfo?.id ?? ''" />
              </div>
            </div>
          </div>
          <div class="modal-mobile-game__controls">
            <button-base v-if="hasSecondaryButton" type="secondary" size="md" class="fun-play" @click="handleFanPlay">
              {{ secondaryContent }}
            </button-base>
            <button-base
              type="primary"
              size="md"
              :class="{ 'full-width': !hasSecondaryButton }"
              class="play-now"
              @click="handlePlayNow"
            >
              <atomic-icon
                v-if="mobileGameModalType && ['depositOrDemo', 'deposit'].includes(mobileGameModalType)"
                id="play"
              />
              <atomic-icon v-else id="user-new" />
              {{ primaryContent }}
            </button-base>
          </div>
        </div>
      </layout-bottom-sheet>
    </client-only>
  </div>
</template>

<script setup lang="ts">
  import type { IBottomSheetComponent } from '@skeleton/types';
  import { ModalName } from '@skeleton/consts/modals';

  const router = useRouter();

  const gameStore = useGamesStore();
  const { showMobileGameModal, mobileGameModalType, mobileGameModalInfo } = storeToRefs(gameStore);
  const { popupsData, defaultLocalePopupsData } = useGlobalStore();
  const { getImageUrl, getContent, localizePath } = useProjectMethods();
  const { openModalSync } = useModalStore();
  const { openWalletModal } = useTransactionStore();
  const { isLoggedIn } = useProfileStore();
  const bottomSheet = ref<Maybe<IBottomSheetComponent>>();

  const gameImage = computed(() => {
    if (mobileGameModalInfo.value?.images?.hasOwnProperty('3x4')) {
      return getImageUrl(mobileGameModalInfo.value.images, 'vertical');
    }
    return '';
  });

  const depositButtonLabel = getContent(popupsData, defaultLocalePopupsData, 'mobileGame.deposit');
  const playDemoButtonLabel = getContent(popupsData, defaultLocalePopupsData, 'mobileGame.demo');
  const registrationButtonLabel = getContent(popupsData, defaultLocalePopupsData, 'mobileGame.registration');

  const modalContent = {
    depositOrDemo: {
      primary: depositButtonLabel,
      secondary: playDemoButtonLabel,
    },
    deposit: {
      primary: depositButtonLabel,
    },
    registerOrDemo: {
      primary: registrationButtonLabel,
      secondary: playDemoButtonLabel,
    },
    registerOrLogin: {
      primary: registrationButtonLabel,
      secondary: getContent(popupsData, defaultLocalePopupsData, 'mobileGame.login'),
      description: getContent(popupsData, defaultLocalePopupsData, 'mobileGame.onlyReal'),
    },
  };

  const hasSecondaryButton = computed(() => {
    const type = mobileGameModalType.value;
    return type && modalContent[type] && 'secondary' in modalContent[type];
  });

  const secondaryContent = computed(() => {
    const type = mobileGameModalType.value;
    if (type && modalContent[type] && 'secondary' in modalContent[type]) {
      return modalContent[type].secondary;
    }
    return '';
  });

  const primaryContent = computed(() => {
    const type = mobileGameModalType.value;
    return type && modalContent[type] ? modalContent[type].primary : '';
  });

  const toggleOpen = (): void => {
    bottomSheet.value?.open();
  };
  const toggleClose = (): void => {
    bottomSheet.value!.close();
  };

  const handlePlayNow = async (): Promise<void> => {
    if (['depositOrDemo', 'deposit'].includes(mobileGameModalType.value as string)) {
      await openWalletModal('deposit');
      await router.push(localizePath(`/game/${mobileGameModalInfo.value?.identity}?real=true`));
    } else {
      openModalSync(ModalName.SIGN_UP);
    }
    showMobileGameModal.value = false;
    toggleClose();
  };
  const handleFanPlay = (): void => {
    if (['depositOrDemo', 'registerOrDemo'].includes(mobileGameModalType.value as string)) {
      router.push(localizePath(`/game/${mobileGameModalInfo.value?.identity}`));
    } else {
      openModalSync(ModalName.SIGN_IN);
    }
    showMobileGameModal.value = false;
    toggleClose();
  };

  const handleSheetClose = (): void => {
    showMobileGameModal.value = false;
  };

  watch(
    () => showMobileGameModal.value,
    (newValue: boolean) => {
      if (newValue) toggleOpen();
    }
  );
</script>

<style src="~/assets/styles/components/bottom-sheet/mobile-game.scss" lang="scss" />
