<template>
  <header :class="getGameView ? 'app-header-root-bottom' : 'app-header-root'">
    <div class="app-header" :class="{ 'app-header--is-login': isLoggedIn }">
      <layout-header-game-mobile v-if="getGameView" />

      <layout-header-default-mobile v-else />
    </div>
  </header>
</template>

<script setup lang="ts">
  const gameStore = useIsGamePlayed();
  const { getGameView } = storeToRefs(gameStore);
  const { isLoggedIn } = storeToRefs(useProfileStore());
</script>

<style src="~/assets/styles/components/layout/header-mobile.scss" lang="scss" />
