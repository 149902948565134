export const MOBILE_MENU = [
  {
    labelPath: 'mobileMenu.home',
    url: '/',
    icon: 'home'
  },
  {
    labelPath: 'mobileMenu.games',
    icon: 'games',
    url: '/games',
  },
  {
    labelPath: 'mobileMenu.sport',
    icon: 'sport',
    url: '/sport',
  },
  {
    labelPath: 'mobileMenu.wallet',
    icon: 'wallet',
    url: '/wallet',
  },
  {
    labelPath: 'mobileMenu.profile',
    icon: 'profile',
    url: '/profile/info',
  }
];
