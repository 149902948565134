export const FOOTER_PLATFORM_MENU = [
  {
    labelPath: 'footer.platformMenu.home',
    url: '/',
  },
  {
    labelPath: 'footer.platformMenu.sports',
    url: '/sport',
  },
  {
    labelPath: 'footer.platformMenu.games',
    url: '/games',
  },
  {
    labelPath: 'footer.platformMenu.wallet',
    url: '/wallet',
  },
  {
    labelPath: 'footer.platformMenu.profile',
    url: '/profile/info',
  },
];

export const FOOTER_PROMO_MENU = [
  {
    labelPath: 'siteSidebar.topMenu.integratedElementVip.label',
    url: '/static/vip_club',
    color: 'pink',
  },
  {
    labelPath: 'footer.infoMenu.referralProgram',
    url: '/static/referral-program',
    color: 'blue',
  },
];

export const FOOTER_INFO_MENU = [
  {
    labelPath: 'footer.infoMenu.amlPolicy',
    url: '/static/amlpolicy',
  },
  {
    labelPath: 'footer.infoMenu.faq',
    url: '/questions',
  },
  {
    labelPath: 'footer.infoMenu.bonusTerms',
    url: '/static/bonus-terms',
  },
  {
    labelPath: 'footer.infoMenu.gamblingWithResponsibility',
    url: '/static/gambling-with-responsibility',
  },
  {
    labelPath: 'footer.infoMenu.kycPolicy',
    url: '/static/kyc-policy',
  },
  {
    labelPath: 'footer.infoMenu.payments',
    url: '/static/payments',
  },
  {
    labelPath: 'footer.infoMenu.privacyPolicy',
    url: '/static/privacy-policy',
  },
  {
    labelPath: 'footer.infoMenu.termsAndConditions',
    url: '/static/terms-and-conditions',
  },
  {
    labelPath: 'footer.infoMenu.vipClub',
    url: '/static/vip_club',
  },
];
