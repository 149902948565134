<template>
  <div class="notification-panel">
    <notification-panel-header
      :totalUnread="totalUnread"
      :currentLocaleData="currentLocaleData"
      :defaultLocaleData="defaultLocaleData"
      @closePanel="closePanel"
      @onReadAll="userNotificationsStore.goToReadAll"
    />

    <div class="notification-panel__body">
      <list-notification
        v-if="hasNotifications"
        :items="items"
        :meta="meta"
        :currentLocaleData="currentLocaleData"
        :defaultLocaleData="defaultLocaleData"
        @loadMore="userNotificationsStore.loadMore"
        @closePanel="closePanel"
        @markAsRead="userNotificationsStore.markAsRead"
      />

      <atomic-empty-box
        v-else
        :title="getContent(currentLocaleData, defaultLocaleData, 'empty.title')"
        :subtitle="getContent(currentLocaleData, defaultLocaleData, 'empty.subtitle')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { INotification } from '~/types';

  defineProps<{
    currentLocaleData: Maybe<INotification>;
    defaultLocaleData: Maybe<INotification>;
  }>();

  const emits = defineEmits<{
    (event: 'closePanel'): void;
  }>();

  const { getContent } = useProjectMethods();
  const userNotificationsStore = useUserNotificationsStore();
  const { items, meta, totalUnread } = storeToRefs(userNotificationsStore);

  const hasNotifications = computed(() => !!items.value.length);

  const closePanel = (): void => {
    emits('closePanel');
  };
</script>

<style src="~/assets/styles/components/notification/notification-panel.scss" lang="scss" />
