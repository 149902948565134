<template>
  <nuxt-link class="logo" to="/">
    <picture>
      <source srcset="/img/web-logo.svg" media="(min-width: 64rem)" >
      <atomic-image class="img" src="/img/logo.svg" />
    </picture>
  </nuxt-link>
</template>

<style src="~/assets/styles/components/atomic/logo.scss" lang="scss" />
