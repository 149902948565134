<template>
  <div class="nav-mob">
    <button-base
      v-for="item in mobileMenu"
      :key="item.url"
      class="nav-mob__item"
      :class="{ active: isActive(item.url) }"
      :url="item.url"
      @click="handleChangeMobileMenu"
    >
      <svg v-if="item.icon" xmlns="http://www.w3.org/2000/svg" class="icon">
        <use :href="getIconHref(item.icon, item.url)" />
      </svg>

      <span class="nav-mob__text">
        {{ item.label }}
      </span>
    </button-base>
  </div>
</template>

<script setup lang="ts">
  import { MOBILE_MENU } from '@skeleton/consts/menus/mobile-menu';
  import { buildMenu } from '@skeleton/utils/menuBuilder';

  const { localizePath } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());
  const { taptic } = useHaptics();
  const { changeMobileMenu } = useIsMobileMenuOpen();
  const route = useRoute();

  const mobileMenu = computed((): IMenuLink[] => {
    return buildMenu(MOBILE_MENU, layoutData.value, defaultLocaleLayoutData.value);
  });

  const isActive = (url: string) => route.path === localizePath(url) || route.path === url;

  const getIconHref = (icon: string, url: string) => `/sprite-nav-mobile.svg#${icon}${isActive(url) ? '-active' : ''}`;

  const handleChangeMobileMenu = () => {
    taptic('soft');
    changeMobileMenu(true);
  };
</script>

<style src="~/assets/styles/components/nav/mob.scss" lang="scss" />
