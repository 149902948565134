<template>
  <div v-if="paymentsItems?.length" class="paysis" :class="{ 'is-logged': isLoggedIn }" ref="sliderNode">
    <div class="paysis__container">
      <div class="paysis__item" v-for="(image, index) in paymentsItems" :key="index">
        <atomic-image class="logo" :src="image" @click="paymentsItemClick" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  import emblaCarouselVue from 'embla-carousel-vue';
  import Autoplay from 'embla-carousel-autoplay';
  import { FOOTER_PAY_LIST } from '@skeleton/consts/method';

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const { isLoggedIn } = storeToRefs(useProfileStore());
  const { openWalletModal } = useTransactionStore();

  const autoplayOptions = {
    delay: 3500,
    stopOnInteraction: false,
    stopOnMouseEnter: true,
  };

  const [sliderNode] = emblaCarouselVue(
    {
      loop: false,
      align: 'start',
    },
    [Autoplay(autoplayOptions)]
  );

  const paymentsItemClick = () => {
    if (isLoggedIn.value) openWalletModal('deposit');
  };

  const paymentsItems = computed(() => {
    const items = FOOTER_PAY_LIST.map(item => `${gamehubCdn}/pay-systems/footer/${item}.svg`);
    return [...items, ...items];
  });
</script>

<style src="~/assets/styles/components/list/paysis.scss" lang="scss" />
