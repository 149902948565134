<template>
  <div class="partners-list">
    <div
      v-for="item in items"
      :key="item.id"
      class="partners-list__item"
    >
      <atomic-image
        class="img"
        :src="getImagePath(item.fileName)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

  const items = [
    { id: 1, fileName: 'partner1' },
    { id: 2, fileName: 'partner2' },
    { id: 3, fileName: 'partner3' },
    { id: 4, fileName: 'partner4' },
    { id: 5, fileName: 'partner5' },
  ];

  const getImagePath = (fileName: string): string => {
    return `/img/partners/${fileName}.svg`;
  };
</script>

<style src="~/assets/styles/components/list/partners.scss" lang="scss" />
