<template>
  <div class="atomic-menu-category-dropdown">
    <atomic-link
      class="atomic-menu-category-dropdown__link"
      :class="[
        { 'atomic-menu-category-dropdown__link--active': checkActiveLink(link) },
        { 'atomic-menu-category-dropdown__link--hidden': !link?.icon },
      ]"
      v-for="link in items"
      :key="link.label"
      :href="link.url"
      :targetBlank="link?.targetBlank"
    >
      <img v-lazy-load  v-if="link?.icon" :data-src="`/img/icons/${link.icon}.svg`" alt="icon" width="24" height="24" />

      <span class="text">{{ link.label }}</span>

      <div v-if="link.badge?.show" class="atomic-menu-category-dropdown__badge" :class="link.badge?.gradientColorClass">
        {{ link.badge?.label }}
      </div>
    </atomic-link>
  </div>
</template>

<script setup lang="ts">
  import type { ILinkItem } from '~/types';

  defineProps<{
    items: ILinkItem[];
  }>();

  const { localizePath } = useProjectMethods();

  const route = useRoute();

  const checkActiveLink = (link: ILinkItem): boolean => {
    return localizePath(link.url) === route.path;
  };
</script>

<style src="~/assets/styles/components/nav/menu/category-dropdown.scss" lang="scss" />
