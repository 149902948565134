export const USER_MENU = [
  {
    labelPath: 'profileSidebar.profileLinks.wallet',
    icon: 'wallet',
    url: '/wallet',
  },
  {
    labelPath: 'profileSidebar.profileLinks.bonuses',
    icon: 'bonuses',
    url: '/bonuses',
  },
  {
    labelPath: 'profileSidebar.profileLinks.history',
    icon: 'history',
    url: '/wallet/history',
  },
];
