<template>
  <div class="toggle-drawer" @click="emit('toggle-minimize')">
    <atomic-icon id="menu-open" data-action="minimize" />
  </div>
</template>

<script setup lang="ts">
  const emit = defineEmits(['toggle-minimize']);
</script>

<style src="~/assets/styles/components/button/toggle-drawer.scss" lang="scss" />

