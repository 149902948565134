<template>
  <div class="atomic-menu-category">
    <atomic-menu-category-activator
      :items="items"
      :icon="icon"
      :label="label"
      :isOpen="isOpen"
      :isDrawerCompact="isDrawerCompact"
      @click="handlerMenu"
    >
      <slot name="activator"></slot>
    </atomic-menu-category-activator>

    <div class="atomic-menu-category__dropdown" :style="styles" ref="contentRef">
      <div class="atomic-menu-category__divider" />

      <atomic-menu-category-dropdown :items="items" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ILinkItem } from '~/types';

  const props = defineProps<{
    label: string;
    icon: string;
    items: ILinkItem[];
    hiddenDropdown?: boolean;
    isDrawerCompact: boolean;
  }>();

  const isOpen = ref(true);

  const contentRef = ref<HTMLElement | null>(null);
  const heightStyle = ref('0px');

  const styles = computed(() => {
    return { height: props.hiddenDropdown ? '0px' : heightStyle.value };
  });

  const handlerMenu = () => {
    if (!contentRef.value) return;

    if (isOpen.value) {
      heightStyle.value = `${contentRef.value.scrollHeight}px`;
      requestAnimationFrame(() => {
        heightStyle.value = '0px';
      });
    } else {
      heightStyle.value = '0px';
      requestAnimationFrame(() => {
        if (contentRef.value) {
          heightStyle.value = `${contentRef.value.scrollHeight}px`;
        }
      });
    }

    isOpen.value = !isOpen.value;
  };

  onMounted(() => {
    if (isOpen.value && contentRef.value) {
      heightStyle.value = `${contentRef.value.scrollHeight}px`;
    }
  });
</script>

<style src="~/assets/styles/components/nav/menu/category.scss" lang="scss" />
