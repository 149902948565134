<template>
  <atomic-link
    class="cta-menu-item"
    @click="clickBtnPlay"
  >
    <atomic-picture v-if="!isDrawerCompact" :src="backgroundImage" />

    <div v-else class="drawerImage">
      <atomic-picture :src="drawerImage" />
    </div>
    <span class="cta-menu-item__text">{{ label }}</span>
  </atomic-link>
</template>

<script setup lang="ts">
  const props = defineProps<{
    url: string,
    label: string,
    backgroundImage?: string,
    drawerImage?: string,
    targetBlank?: boolean
  }>();
  const router = useRouter();
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { localizePath } = useProjectMethods();

  const clickBtnPlay = (): void => {
    const query = isLoggedIn.value ? '?real=true' : '';
    router.push(localizePath(`${props.url}${query}`));
  };

  const layoutStore = useLayoutStore();
  const { isDrawerCompact } = storeToRefs(layoutStore);
</script>

<style src="~/assets/styles/components/atomic/cta-menu-item.scss" lang="scss"/>

