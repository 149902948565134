export const GAMES_MENU = [
  {
    labelPath: 'siteSidebar.gamesMenu.favorites',
    url: '/favorites',
    icon: 'heart'
  },
  {
    labelPath: 'siteSidebar.gamesMenu.recentlyPlayed',
    icon: 'recently-played',
    url: '/recently-played',
  },
  {
    labelPath: 'siteSidebar.gamesMenu.newGames',
    icon: 'new-games',
    url: '/games/new',
  },
  {
    labelPath: 'siteSidebar.gamesMenu.liveCasino',
    icon: 'live',
    url: '/games/livecasino',
  },
  {
    labelPath: 'siteSidebar.gamesMenu.tableGames',
    icon: 'table-games',
    url: '/games/tablegames',
  },
  {
    labelPath: 'siteSidebar.gamesMenu.slots',
    icon: 'slots-games',
    url: '/games/slots',
  },
];

