<template>
  <notifications :position="isMobile ? 'bottom center' : 'top right'" :duration="8000" :speed="200" :width="358">
    <template #body="{ item, close }">
      <div class="alert" :class="`variant-${item.type}`">
        <div class="alert__header">
          <atomic-icon :id="types[item.type]" />

          <div class="title">
            {{ item.title || item.text }}
          </div>
        </div>

        <p v-if="item.title && item.text" class="text" v-html="item.text" />

        <button class="btn-alert-close">
          <atomic-icon id="close" @click="close" />
        </button>
      </div>
    </template>
  </notifications>
</template>

<script setup lang="ts">
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const types: { [index: string]: string } = {
    info: 'info',
    error: 'warning',
    warning: 'info',
    done: 'done',
    pending: 'attention',
  };
</script>

<style src="~/assets/styles/components/atomic/alert.scss" lang="scss" />
