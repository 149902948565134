export interface IMenuItem {
  labelPath: string;
  icon?: string;
  color?: string;
  url: string;
}

export interface IMenuLink {
  label: string;
  icon?: string;
  color?: string;
  url: string;
}

const { getContent } = useProjectMethods();

export const buildMenu = (menuArray: IMenuItem[], contentData: any, defaultContentData: any): IMenuLink[] => {
  return menuArray.map((menuItem: IMenuItem) => {
    return {
      label: getContent(contentData, defaultContentData, menuItem.labelPath),
      url: menuItem.url,
      ...(menuItem.icon && { icon: menuItem.icon }),
      ...(menuItem.color && { color: menuItem.color }),
    };
  });
};
