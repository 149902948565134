<template>
  <div class="select-lang" :class="{ 'select-lang_is-open': isOpen, border: !isHeader }">
    <client-only>
      <div v-click-outside="closeSelect" class="select-lang__wrap">
        <div class="select-lang__selected" :class="{ 'header-lang-height': isHeader }" @click="toggleOpen">
          <atomic-image class="select-lang__img" :src="localeFlagSrc" />

          <template v-if="!isHeader">
            <span class="title">{{ currentLocale?.code || currentLocale?.name }}</span>

            <atomic-icon id="arrow_expand-close" />
          </template>
        </div>

        <div class="select-lang__items" body-scroll-lock-ignore>
          <div class="header">
            <span class="title">
              {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.language.label') }}
            </span>

            <form-input-search
              v-model:value="searchValue"
              :placeholder="
                getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.search.placeholder')
              "
            />
          </div>

          <list-languages :searchValue="searchValue" />
        </div>
      </div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
  defineProps<{
    isHeader?: boolean;
  }>();

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const { getContent } = useProjectMethods();
  const globalStore = useGlobalStore();
  const { fieldsSettings, defaultLocaleFieldsSettings, currentLocale } = storeToRefs(globalStore);

  const isOpen = ref(false);
  const isProcess = ref(false);
  const searchValue = ref('');

  const localeFlagSrc = computed(() => `${gamehubCdn}/locales/${currentLocale.value?.code.toLowerCase()}.svg`);

  const toggleOpen = (): void => {
    if (isProcess.value) return;
    isOpen.value = !isOpen.value;
  };

  const closeSelect = (): void => {
    if (isOpen.value) isOpen.value = false;
  };
</script>

<style src="~/assets/styles/components/atomic/select-lang.scss" lang="scss" />
