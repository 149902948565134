<template>
  <div class="list-socials">
    <atomic-link v-for="(item, index) in props.items" :key="index" class="list-socials__item">
      <atomic-icon :id="item.icon" />
    </atomic-link>
  </div>
</template>

<script setup lang="ts">
  import type { ISocialLinkItem } from '~/types';

  const props = defineProps<{
    items: ISocialLinkItem[];
  }>();
</script>

<style src="~/assets/styles/components/list/socials.scss" lang="scss" />
