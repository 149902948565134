<template>
  <button
    class="btn-search"
    @click="emit('show-search')"
  >
    <atomic-icon id="search" />
  </button>
</template>

<script setup lang="ts">
  const emit = defineEmits(['show-search']);

</script>

<style src="~/assets/styles/components/button/search.scss" lang="scss" />
