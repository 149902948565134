<template>
  <div class="nav-group">
    <div v-if="title" class="nav-group__title">{{ title }}</div>
    <ul class="nav-group__list">
      <li v-for="item in items" :key="item.label" :title="item.label">
        <nuxt-link
          :class="[
            'nav-group__item',
            { 'nav-group__item_active': isActive(item.url) },
            item.color ? `nav-group__item_${item.color}` : '',
          ]"
          :to="localizePath(item.url)"
        >
          {{ item.label }}
          <span v-if="item.color" :class="['nav-group__icon', `nav-group__icon_${item.color}`]" />
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
  interface IItem {
    url: string;
    label: string;
    icon?: string;
    color?: string;
  }

  interface IProps {
    title?: string;
    items: IItem[];
  }

  const route = useRoute();
  const { localizePath } = useProjectMethods();

  const isActive = (url: string) => route.path === localizePath(url);

  defineProps<IProps>();
</script>

<style src="~/assets/styles/components/nav/group.scss" lang="scss" />
