<template>
  <component v-if="skeletonComponent" :is="skeletonComponent" />
</template>

<script setup lang="ts">
  const loadingStore = useLoadingStore();
  const skeletonComponent = shallowRef(null);

  const existsComponents = [
    'profile-info-page',
    'profile-personal-page',
    'profile-limits-page',
    'profile-sessions-page',
    'bonuses-page',
    'referral-page',
    'index-page',
    'welcome-package-page',
    'bonus-pageIdentity-page',
  ];

  const componentCache = new Map<string, any>();

  const loadComponent = async (componentName: string) => {
    if (componentCache.has(componentName)) {
      return componentCache.get(componentName);
    }

    try {
      const module = await import(`@skeleton/components/skeleton-preloader/${componentName}.vue`);
      componentCache.set(componentName, module.default);
      return module.default;
    } catch (error) {
      console.error(`Component ${componentName} not found.`, error);
      return null;
    }
  };

  const preloadComponents = async () => {
    for (const componentName of existsComponents) {
      try {
        const module = await import(`@skeleton/components/skeleton-preloader/${componentName}.vue`);
        componentCache.set(componentName, module.default);
      } catch (error) {
        console.error(`Error preloading component ${componentName}:`, error);
      }
    }
  };

  const handleRouteChange = async () => {
    const componentName = loadingStore.skeletonComponentName;

    if (existsComponents.includes(componentName)) {
      skeletonComponent.value = await loadComponent(componentName);
    } else {
      console.warn(`Component ${componentName} does not exist.`);
      skeletonComponent.value = null;
      loadingStore.setLoadingState(false);
    }
  };

  watch(() => loadingStore.routeName, handleRouteChange, { immediate: true });

  onMounted(preloadComponents);
</script>
